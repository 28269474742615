import { PrivateRoute } from '@dimatech/features-core/lib/components/PrivateRoute';
import {
  AuthenticationContext,
  AuthenticationProvider,
} from '@dimatech/features-core/lib/features/authentication';
import { CookieConsent } from '@dimatech/features-core/lib/features/cookie-consent';
import {
  useGoogleAnalytics,
  useLaunchDarkly,
} from '@dimatech/features-core/lib/hooks';
import { ApplicationProvider } from '@dimatech/shared/lib/application';
import { LocalizationProvider } from '@dimatech/shared/lib/localization';
import { ThemeProvider } from '@dimatech/shared/lib/themes';
import { TrackingProvider } from '@dimatech/shared/lib/tracking';
import { themes } from 'assets/themes';
import { GlobalStyles } from 'assets/themes/GlobalStyles';
import { CommonRoles } from 'models';
import { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ContactedProjects } from 'views/ContactedProjects';
import { CookieInformation } from 'views/CookieInformation';
import { FollowedProjects } from 'views/FollowedProjects';
import { ForgotPassword } from 'views/ForgotPassword';
import { GlobalAdministrateAppConfig } from 'views/GlobalAdministrateAppConfig';
import { Home } from 'views/Home';
import { LoginWithAccount } from 'views/LoginWithAccount';
import { LoginWithLink } from 'views/LoginWithLink';
import { LoginWithLinkConfirmation } from 'views/LoginWithLinkConfirmation';
import { Newsletter } from 'views/Newsletter';
import { Project } from 'views/Project';
import { ResetPassword } from 'views/ResetPassword';
import { Search } from 'views/Search';
import { StyleGuide } from 'views/StyleGuide';

const App = (): JSX.Element => {
  return (
    <AuthenticationProvider>
      <WithAuthentication />
    </AuthenticationProvider>
  );
};

export default App;

// We need to separate this from App so we can access AuthenticationContext
const WithAuthentication = (): JSX.Element => {
  const { accessToken } = useContext(AuthenticationContext);

  useLaunchDarkly();
  useGoogleAnalytics(false);

  return (
    <LocalizationProvider defaultLanguage={accessToken.locale.language}>
      <TrackingProvider>
        <ThemeProvider themes={themes}>
          <ApplicationProvider>
            <CookieConsent />

            <GlobalStyles />

            <Routes>
              <Route path="*" element={<Home />} />

              <Route
                path="/login-with-account"
                element={<LoginWithAccount />}
              />

              <Route path="/login" element={<LoginWithLink />} />
              <Route
                path="/welcome/:token"
                element={<LoginWithLinkConfirmation />}
              />
              <Route path="/newsletter" element={<Newsletter />} />
              <Route path="/cookies" element={<CookieInformation />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route
                path="/reset-password/:resetToken"
                element={<ResetPassword />}
              />

              <Route
                path="/global-administrate/appconfig"
                element={
                  <PrivateRoute
                    requireRole={[CommonRoles.GlobalAdmin]}
                    isWebTokenAllowed={true}
                    isBaseLicenseAllowed={true}
                  >
                    <GlobalAdministrateAppConfig />
                  </PrivateRoute>
                }
              />

              <Route
                path="/style-guide"
                element={
                  <PrivateRoute
                    requireRole={[CommonRoles.GlobalAdmin]}
                    isWebTokenAllowed={true}
                    isBaseLicenseAllowed={true}
                  >
                    <StyleGuide />
                  </PrivateRoute>
                }
              />

              <Route
                path="/project/:id"
                element={
                  <PrivateRoute
                    isWebTokenAllowed={true}
                    isBaseLicenseAllowed={true}
                  >
                    <Project />
                  </PrivateRoute>
                }
              />

              <Route
                path="/search"
                element={
                  <PrivateRoute
                    isWebTokenAllowed={true}
                    isBaseLicenseAllowed={true}
                  >
                    <Search />
                  </PrivateRoute>
                }
              />

              <Route
                path="/followed-projects"
                element={
                  <PrivateRoute
                    isWebTokenAllowed={true}
                    isBaseLicenseAllowed={true}
                  >
                    <FollowedProjects />
                  </PrivateRoute>
                }
              />

              <Route
                path="/contacted-projects"
                element={
                  <PrivateRoute
                    isWebTokenAllowed={true}
                    isBaseLicenseAllowed={true}
                  >
                    <ContactedProjects />
                  </PrivateRoute>
                }
              />
            </Routes>
          </ApplicationProvider>
        </ThemeProvider>
      </TrackingProvider>
    </LocalizationProvider>
  );
};
