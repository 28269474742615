import { MultiSelect } from '@dimatech/shared/lib/components/form';
import { Node, Phase } from 'models';
import { useTranslation } from 'react-i18next';

export const SelectPhases = ({
  phases,
  setPhases,
  inverted = false,
}: {
  phases?: string[];
  setPhases: (phases: Phase[]) => void;
  inverted?: boolean;
}): JSX.Element | null => {
  const { t } = useTranslation();

  const nodes: Node<Phase>[] = Object.keys(Phase).map((value) => ({
    id: value,
    name:
      value === Phase.ReadyForReview
        ? t(`Project.Phase.Published`)
        : t(`Project.Phase.${value}`),
    parentId: null,
    payload: value as Phase,
  }));

  const handleChange = (selectedNodes: Node<Phase>[]) => {
    setPhases(selectedNodes.map((node) => node.payload));
  };

  return (
    <>
      {nodes && (
        <MultiSelect
          inverted={inverted}
          style={{
            minWidth: 200,
          }}
          placeholder={t('Search.Phases')}
          options={nodes}
          setSelected={(selectedNodes) => {
            handleChange(selectedNodes);
          }}
          showSelectAll={false}
          selected={nodes.filter((node) => phases?.includes(node.id))}
        />
      )}
    </>
  );
};

SelectPhases.displayName = 'SelectPhases';
