import { SelectTags } from '@dimatech/features-core/lib/components/SelectTags';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Input } from '@dimatech/shared/lib/components/form';
import { LoaderOverlay } from '@dimatech/shared/lib/components/loader';
import {
  Pagination,
  useSortablePaginator,
} from '@dimatech/shared/lib/components/paginator';
import {
  Table,
  TableResponsiveContainer,
  Td,
  TdRight,
  Th,
  ThRight,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { Breakpoints } from '@dimatech/shared/lib/themes';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { piosPrivateActions, selectFilter } from 'api/piosPrivateSlice';
import {
  useGetCommonTagsQuery,
  useLazySearchQuery,
} from 'api/private/searchApi';
import img_hero from 'assets/images/nature-1-hero.jpg';
import { Container } from 'components/Container';
import { HeroText, HeroTitle } from 'components/Hero';
import { HeroProduct } from 'components/HeroProduct';
import { Section } from 'components/Section';
import { SelectCustomers } from 'components/SelectCustomers';
import { SelectPhases } from 'components/SelectPhases';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Paginator, Phase, Project, SortDirection } from 'models';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsSearch } from 'react-icons/bs';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce';
import { formatAsNumber } from 'utils';

export const Search = (): JSX.Element => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);

  const dispatch = useAppDispatch();
  const filter = useAppSelector(selectFilter);

  const isAreasOfOperationEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isAreasOfOperationEnabled];

  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams] = useSearchParams();
  const tag = searchParams.get('tag');

  const [searchTerm, setSearchTerm] = useState<string>();
  const [projects, setProjects] = useState<Project[]>([]);
  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);

  const [search, { data, isFetching, isLoading }] = useLazySearchQuery();
  const { data: tags } = useGetCommonTagsQuery(
    isAreasOfOperationEnabledFlagOn ?? skipToken
  );

  const initialPaginator: Paginator = {
    page: 1,
    pageSize: 25,
    orderBy: 'title',
    orderDirection: SortDirection.Asc,
  };

  const handleSearch = (paginator: Paginator = initialPaginator) => {
    search(
      {
        filter,
        paginator,
      },
      true
    );
  };

  const handleChangePhase = (phases: Phase[]) => {
    dispatch(piosPrivateActions.setFilterPhase(phases));
  };

  const handleChangeCustomers = (customerIds: string[]) => {
    dispatch(piosPrivateActions.setFilter({ ...filter, customerIds }));
  };

  const handleChangeTag = (tagIds: string[]) => {
    dispatch(piosPrivateActions.setFilterTagIds(tagIds));
  };

  const { setPage, paginator, sorter } = useSortablePaginator({
    ...initialPaginator,
    handlePaginatorChange: handleSearch,
  });

  useEffect(() => {
    setProjects([]);
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    dispatch(
      piosPrivateActions.setFilter({
        ...filter,
        searchTerm: debouncedSearchTerm,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  useEffect(() => {
    setProjects(data?.records ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (tag) {
      dispatch(piosPrivateActions.setFilterTagIds([tag]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tag, searchParams]);

  return (
    <>
      <HeroProduct image={img_hero} style={{ minHeight: 180 }}>
        <HeroTitle>{t('Search.Title')}</HeroTitle>
        <HeroText>{t('Search.Text')}</HeroText>
      </HeroProduct>

      <Section style={{ marginBottom: 50 }}>
        <Container>
          <Style>
            <SearchStyle>
              <div>
                <Input
                  value={searchTerm ?? ''}
                  placeholder={t('Search.Placeholder')}
                  onChange={(e) => setSearchTerm(e.currentTarget.value)}
                />
                <BsSearch />
              </div>

              <SelectCustomers
                customers={filter.customerIds}
                setCustomers={handleChangeCustomers}
              />

              <SelectPhases
                phases={filter.phases}
                setPhases={handleChangePhase}
              />

              {isAreasOfOperationEnabledFlagOn && (
                <SelectTags
                  tags={tags}
                  tagIds={filter?.tagIds}
                  setTagIds={handleChangeTag}
                  placeholder={t('Search.Tags')}
                  showSelectAll={false}
                  style={{ width: 300 }}
                />
              )}
            </SearchStyle>

            {(isFetching || isLoading) && (
              <div style={{ margin: '40px 0' }}>
                <LoaderOverlay>{t('Common.UI.Loading')}</LoaderOverlay>
              </div>
            )}

            {data && data.totalRecords === 0 && (
              <div style={{ marginTop: 20 }} className="i">
                {t('Search.NoProjects')}
              </div>
            )}

            {data && data.totalRecords > 0 && (
              <>
                <Pagination
                  currentPage={paginator.page}
                  totalCount={data?.totalRecords ?? 0}
                  pageSize={paginator.pageSize}
                  handlePageChange={(page) => setPage(page)}
                  style={{ marginTop: 40 }}
                />

                <TableResponsiveContainer style={{ marginTop: 40 }}>
                  <Table>
                    <thead>
                      <tr>
                        <Th sortKey="customerName" sorter={sorter}>
                          {t('Project.OrganisationName')}
                        </Th>
                        <Th sortKey="title" sorter={sorter}>
                          {t('Project.Title')}
                        </Th>
                        <Th sortKey="projectPhase" sorter={sorter}>
                          {t('Project.ProjectPhase')}
                        </Th>
                        <ThRight
                          sortKey="budget"
                          sorter={sorter}
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          {t('Project.Budget', {
                            currency: t(
                              `Common.Currency.${accessToken.locale.currency}.Name`
                            ),
                          })}
                        </ThRight>
                        {isAreasOfOperationEnabledFlagOn && (
                          <Th sortKey="nationalTagsSorted" sorter={sorter}>
                            {t('Project.Tags')}
                          </Th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {projects.map((project) => (
                        <Tr
                          key={project.projectId}
                          isHoverable={true}
                          onSelect={() => {
                            navigate(`/project/${project.projectId}`, {
                              state: { from: location.pathname },
                            });
                          }}
                        >
                          <Td>{project.customerName}</Td>
                          <Td>{project.title}</Td>
                          <Td>
                            {project.projectPhase === Phase.ReadyForReview
                              ? t(`Project.Phase.Published`)
                              : t(`Project.Phase.${project.projectPhase}`)}
                          </Td>
                          <TdRight>{formatAsNumber(project.budget)}</TdRight>
                          {isAreasOfOperationEnabledFlagOn && (
                            <Td>
                              {project.nationalTags
                                ?.map((t) => t.displayName)
                                .join(', ')}
                            </Td>
                          )}
                        </Tr>
                      ))}
                    </tbody>
                  </Table>
                </TableResponsiveContainer>

                <Pagination
                  currentPage={paginator.page}
                  totalCount={data?.totalRecords ?? 0}
                  pageSize={paginator.pageSize}
                  handlePageChange={(page) => setPage(page)}
                  style={{ marginTop: 40 }}
                />
              </>
            )}
          </Style>
        </Container>
      </Section>
    </>
  );
};

Search.displayName = 'Search';

const Style = styled.div`
  padding-top: 30px;
`;

const SearchStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px 20px;

  margin: 20px 10px 0 10px;

  select {
    width: 180px;
  }

  > div:first-of-type {
    min-width: 350px;
    display: flex;
    align-items: center;

    > input:first-of-type {
      max-width: 500px;
      padding-right: 25px;
    }

    > svg {
      margin-left: -20px;
    }

    @media screen and (max-width: ${Breakpoints.small}) {
      order: 2;
      margin-right: 0;
      width: 100%;

      > input {
        width: 100%;
        max-width: unset;
      }
    }
  }
`;
