import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { BackButton } from '@dimatech/shared/lib/components/BackButton';
import { LoaderOverlay } from '@dimatech/shared/lib/components/loader';
import { Tags } from '@dimatech/shared/lib/components/Tag';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { Heading1, Heading4 } from '@dimatech/shared/lib/components/typography';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { trackEvent } from '@dimatech/shared/lib/tracking';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useContactProjectMutation } from 'api/private/contactApi';
import { getLogo } from 'api/private/customerLogoSlice';
import {
  useFollowProjectMutation,
  useUnfollowProjectMutation,
} from 'api/private/followApi';
import { useGetProjectQuery } from 'api/private/searchApi';
import { Container } from 'components/Container';
import { Section } from 'components/Section';
import { parseISO } from 'date-fns';
import { useAppDispatch } from 'hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { EventType, Phase } from 'models';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsEnvelope, BsEye, BsEyeSlash } from 'react-icons/bs';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { formatAsCurrency, formatDate } from 'utils';
import { ContactConfirmation } from './components/ContactConfirmation';
import { FollowConfirmation } from './components/FollowConfirmation';

export const Project = (): JSX.Element => {
  const { t } = useTranslation();
  const { accessToken, webToken } = useContext(AuthenticationContext);

  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const { id = '' } = useParams();

  const isAreasOfOperationEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isAreasOfOperationEnabled];

  const [image, setImage] = useState<string>();
  const [isChangingFollow, setIsChangingFollow] = useState(false);
  const [isChangingContact, setIsChangingContact] = useState(false);

  const {
    data: project,
    isSuccess,
    isFetching,
    isLoading,
  } = useGetProjectQuery(
    id && (accessToken.user?.id || webToken.token)
      ? {
          projectId: id,
          _userId: accessToken?.user?.id ?? webToken.token ?? '',
        }
      : skipToken
  );

  const [contact] = useContactProjectMutation();
  const [follow] = useFollowProjectMutation();
  const [unfollow] = useUnfollowProjectMutation();

  const handleChangeContact = () => {
    if (!project?.projectId) {
      return;
    }

    setIsChangingContact(false);
    contact(project.projectId);
    trackEvent(EventType.ProjectContact);
  };

  const handleChangeFollow = () => {
    if (!project?.projectId) {
      return;
    }

    setIsChangingFollow(false);

    if (project.isFollowed) {
      unfollow(project.projectId);
      trackEvent(EventType.ProjectUnfollow);
    } else {
      follow(project.projectId);
      trackEvent(EventType.ProjectFollow);
    }
  };

  const handleBack = () => {
    navigate((location.state as { from: string })?.from ?? `/search`);
  };

  const handleDownloadLogo = () => {
    if (project) {
      dispatch(getLogo({ customerId: project.customerId })).then((response) => {
        setImage(response.payload as string);
      });
    }
  };

  useEffect(() => {
    handleDownloadLogo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  return (
    <Section style={{ marginBottom: 50 }}>
      <Container>
        <Style>
          {(isFetching || isLoading) && (
            <div style={{ margin: '40px 0' }}>
              <LoaderOverlay>{t('Common.UI.Loading')}</LoaderOverlay>
            </div>
          )}

          {!project && isSuccess && (
            <div style={{ marginTop: 20 }} className="i">
              {t('Project.NoSuchProject')}
            </div>
          )}

          {project && (
            <>
              <div style={{ display: 'flex', marginTop: 40 }}>
                <BackButton handleBack={handleBack} />
                <Heading1>{project.title}</Heading1>
              </div>

              <p>{project.description}</p>

              {project.benefitsAndEffects && (
                <div style={{ marginBottom: 20 }}>
                  <Heading4>{t('Project.BenefitsAndEffects')}</Heading4>
                  <div>{project.benefitsAndEffects}</div>
                </div>
              )}

              <SummaryStyle>
                {!!image && (
                  <div>
                    <img alt={project.customerName} src={image} />
                  </div>
                )}

                <div>
                  <Heading4>
                    {t('Project.OrganisationName')}: {project.customerName}
                  </Heading4>
                  <p>
                    {t('Project.ProjectPhase')}:{' '}
                    {project.projectPhase === Phase.ReadyForReview
                      ? t(`Project.Phase.Published`)
                      : t(`Project.Phase.${project.projectPhase}`)}
                  </p>
                  <p>
                    {t('Project.Budget', {
                      currency: t(
                        `Common.Currency.${accessToken.locale.currency}.Name`
                      ),
                    })}
                    : {formatAsCurrency(project.budget)}
                  </p>

                  {isAreasOfOperationEnabledFlagOn && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        gap: 10,
                      }}
                    >
                      <div>{t('Project.Tags')}:</div>{' '}
                      {project.nationalTags &&
                      project.nationalTags.length > 0 ? (
                        <Tags
                          onDelete={undefined}
                          onClick={(tag) => navigate(`/search?tag=${tag.id}`)}
                          tags={project.nationalTags}
                        />
                      ) : (
                        '-'
                      )}
                    </div>
                  )}
                </div>
              </SummaryStyle>

              <div style={{ marginTop: 50 }}>
                {project.isContacted && project.contactedDate && (
                  <div>
                    <LinkWithTooltip
                      handleClick={() => setIsChangingContact(true)}
                      isPrimary={false}
                      title={t('ContactAgain.Title')}
                      tooltipTitle={t('ContactAgain.TooltipTitle')}
                      tooltip={t('ContactAgain.Tooltip')}
                      icon={<BsEnvelope />}
                    />
                    <div style={{ marginTop: 10 }}>
                      {t('Project.ContactedDate')}:{' '}
                      {formatDate(parseISO(project.contactedDate))}
                    </div>
                  </div>
                )}

                {!project.isContacted && (
                  <LinkWithTooltip
                    handleClick={() => setIsChangingContact(true)}
                    isPrimary={false}
                    title={t('Contact.Title')}
                    tooltipTitle={t('Contact.TooltipTitle')}
                    tooltip={t('Contact.Tooltip')}
                    icon={<BsEnvelope />}
                  />
                )}

                <div style={{ marginTop: 20 }}>
                  {project.isFollowed && project.followedDate && (
                    <>
                      <LinkWithTooltip
                        handleClick={() => setIsChangingFollow(true)}
                        isPrimary={false}
                        title={t('SaveRemove.Title')}
                        tooltipTitle={t('SaveRemove.TooltipTitle')}
                        tooltip={t('SaveRemove.Tooltip')}
                        icon={<BsEyeSlash />}
                      />
                      <div style={{ marginTop: 10 }}>
                        {t('Project.FollowedDate')}:{' '}
                        {formatDate(parseISO(project.followedDate))}
                      </div>
                    </>
                  )}

                  {!project.isFollowed && (
                    <LinkWithTooltip
                      handleClick={() => setIsChangingFollow(true)}
                      isPrimary={false}
                      title={t('Save.Title')}
                      tooltipTitle={t('Save.TooltipTitle')}
                      tooltip={t('Save.Tooltip')}
                      icon={<BsEye />}
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </Style>
      </Container>

      {project && isChangingFollow && (
        <FollowConfirmation
          project={project}
          setIsChangingFollow={setIsChangingFollow}
          handelChangeFollow={handleChangeFollow}
        />
      )}

      {project && isChangingContact && (
        <ContactConfirmation
          project={project}
          setIsChangingContact={setIsChangingContact}
          handleChangeContact={handleChangeContact}
        />
      )}
    </Section>
  );
};

Project.displayName = 'Project';

const Style = styled.div`
  padding-top: 30px;
`;

const SummaryStyle = styled.div`
  display: flex;
  gap: 15px;

  img {
    max-height: 150px;
  }
`;
