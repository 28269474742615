import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { CookieConsentSelection } from '@dimatech/features-core/lib/features/cookie-consent';
import { DevToolbar } from '@dimatech/shared/lib/components/dev-tools';
import { ButtonLink } from '@dimatech/shared/lib/components/form';
import { Theme } from '@dimatech/shared/lib/themes';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { withTheme } from 'styled-components';
import { Container } from './Container';
import { DimatechLogoFooter } from './DimatechLogo';

export const Footer = withTheme(({ theme }: { theme: Theme }): JSX.Element => {
  const { t } = useTranslation();
  const { accessToken, webToken } = useContext(AuthenticationContext);

  const [hasSavedConsent, setHasSavedConsent] = useState(true);

  return (
    <>
      <Style>
        <Container>
          <div>
            <DimatechLogoFooter />
            <DevToolbar
              token={accessToken.token}
              userId={accessToken.user?.id}
              customerId={accessToken.customerId}
              tokenExpires={accessToken.expires}
              webToken={webToken.token}
              webTokenExpires={webToken.expires}
            />
          </div>

          <div>
            <div>
              <p>
                <b>{t('Footer.Contact.Text')}</b>
                <br />
                <a href="mailto:info@dimatech.se">info@dimatech.se</a>
                <br />
                Box 7159
                <br />
                103 88 Stockholm
                <br />
                <a href="https://www.dimatech.se" className="b">
                  www.dimatech.se
                </a>
              </p>
            </div>

            <div>
              <p>
                <b>Support</b>
                <br />
                {t('Footer.Support.Text')}
                <br />
                <a href="mailto:support@dimatech.se" className="b">
                  support@dimatech.se
                </a>
                <br />
                <br />
                <ButtonLink
                  onClick={() => {
                    setHasSavedConsent(false);
                  }}
                >
                  {t('Footer.Cookies.Text')}
                </ButtonLink>
              </p>
            </div>
          </div>
        </Container>
      </Style>

      <CookieConsentSelection
        hasSavedConsent={hasSavedConsent}
        setHasSavedConsent={setHasSavedConsent}
      />
    </>
  );
});

const Style = styled.div`
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  background-color: ${({ theme }: { theme: Theme }) =>
    theme.colors.surfaceVariant};
  color: ${({ theme }: { theme: Theme }) => theme.colors.onSurfaceVariant};

  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;

    padding: 40px 30px;

    > div {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 60px;

      a,
      > div > p > button {
        border: none;
        color: ${({ theme }: { theme: Theme }) =>
          theme.colors.onSurfaceVariant};

        &:hover {
          color: ${({ theme }: { theme: Theme }) => theme.colors.primary};
        }
      }
    }
  }
`;
