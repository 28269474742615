import { appActions } from '@dimatech/features-core/lib/api/appSlice';
import {
  useAuthenticateMutation,
  useRefreshAuthTokenMutation,
} from '@dimatech/features-core/lib/api/authentication/authenticationApi';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { AlertError } from '@dimatech/shared/lib/components/Alert';
import { Input, Label } from '@dimatech/shared/lib/components/form';
import { Heading2 } from '@dimatech/shared/lib/components/typography';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { trackEvent } from '@dimatech/shared/lib/tracking';
import { Button } from 'components/Button';
import { Container } from 'components/Container';
import { Section } from 'components/Section';
import { config } from 'config';
import { useAppDispatch } from 'hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Authentication, EventType } from 'models';
import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

export const LoginWithAccount = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const publishPubliclyMinProjectsRequirementFlagValue =
    useFlags()[flags.permanent.app.pios.publishPubliclyMinProjectsRequirement];

  const [
    publishPubliclyMinProjectsRequirement,
    setPublishPubliclyMinProjectsRequirement,
  ] = useState<number>(publishPubliclyMinProjectsRequirementFlagValue);
  const [errorName, setErrorName] = useState<string>();

  const { accessToken, refreshToken, webToken, logout } = useContext(
    AuthenticationContext
  );

  const state: { email: string } = location.state as { email: string };

  const [authenticate, { data, error, isLoading }] = useAuthenticateMutation();
  const [refreshAuthToken] = useRefreshAuthTokenMutation();

  const [login, setLogin] = useState<Authentication>({
    login: state?.email || '',
    password: '',
  });

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (!login.login || !login.password) {
      return;
    }

    authenticate({ authentication: login });

    trackEvent(EventType.AuthLogin, {
      login_type: 'Account',
    });
  };

  const handleChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const target = event.currentTarget;

    setLogin({
      ...login,
      [target.name]: target.value.trim(),
    });
  };

  useEffect(() => {
    // Check if refresh token is set and valid, then we are logged in
    if (refreshToken.isValid()) {
      // Check if token is set and is valid, then we are
      // authed within this app and can redirect
      if (accessToken.isValid()) {
        handleLoggedIn();

        return;
      }

      // If auth token is missing or invalid, request a
      // token using refresh token
      refreshAuthToken({ refreshToken: refreshToken.token as string })
        .unwrap()
        .then((result) => {
          accessToken.update(result.accessToken);
          refreshToken.update(result.refreshToken);

          handleLoggedIn();
        })
        .catch(() => {
          // Ignore error, just logout
          logout();
        });
    }

    if (webToken.isValid()) {
      handleLoggedIn();

      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      accessToken.update(data.accessToken);
      refreshToken.update(data.refreshToken);

      handleLoggedIn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (error) {
      logout();
    }

    if (
      error?.name &&
      error.name.startsWith('MinimumProjectPublishedCriteriaFailed')
    ) {
      // Number of required projects to publish publicly is included in error message
      // since it can differ based on customer feature flag setting
      setErrorName('MinimumProjectPublishedCriteriaFailed');
      setPublishPubliclyMinProjectsRequirement(
        Number(error.name.replace('MinimumProjectPublishedCriteriaFailed', ''))
      );
    } else {
      // Different error, set default error name and requirement
      setErrorName(error?.name);
      setPublishPubliclyMinProjectsRequirement(
        publishPubliclyMinProjectsRequirementFlagValue
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    dispatch(appActions.resetApplication());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoggedIn = () => {
    navigate((location.state as { from: string })?.from ?? '/search');
  };

  return (
    <Section style={{ marginTop: 30, marginBottom: 30 }}>
      <Container>
        <div>
          <form onSubmit={handleSubmit}>
            <Heading2>{t('Login.Title')}</Heading2>

            <div style={{ marginTop: 20, marginBottom: 10 }}>
              {t('Login.Text')}
            </div>

            <LinkContainer style={{ marginBottom: 30 }}>
              <Link
                to="/login"
                state={{
                  from: (location.state as { from: string })?.from ?? '/',
                }}
              >
                {t('Login.RequestWebLoginToken')}
              </Link>
            </LinkContainer>

            {error && errorName && (
              <Error style={{ marginBottom: 30 }}>
                {t(`Login.ValidationError.${errorName}`, {
                  publishPubliclyMinProjectsRequirement,
                })}
              </Error>
            )}

            <div>
              <Label htmlFor="login">{t('Login.Login')}</Label>
              <Input
                type="text"
                id="login"
                name="login"
                defaultValue={login.login}
                onChange={handleChange}
              />
            </div>

            <div>
              <Label htmlFor="password">{t('Login.Password')}</Label>
              <Input
                type="password"
                id="password"
                name="password"
                defaultValue={login.password}
                onChange={handleChange}
              />
            </div>

            <Button
              handleClick={handleSubmit}
              style={{ width: '100%', marginTop: 20, marginBottom: 20 }}
              disabled={isLoading}
              text={isLoading ? t('Login.Submitting') : t('Login.Submit')}
            />

            <LinkContainer>
              <Link to="/forgot-password">{t('Login.ForgotPasswordLink')}</Link>
            </LinkContainer>

            <LinkContainer>
              <div>{t('Cookies.Notice.Text')}</div>
              <Link to="/cookies">{t('Cookies.Notice.ReadMore')}</Link>
            </LinkContainer>

            <LinkContainer>
              <a href={`${config.products.pios.url}/register`}>
                {t('Login.Register')}
              </a>
            </LinkContainer>
          </form>
        </div>
      </Container>
    </Section>
  );
};

LoginWithAccount.displayName = 'LoginWithAccount';

const LinkContainer = styled.div`
  margin-top: 15px;
`;

const Error = styled(AlertError)`
  margin: 15px 0 10px;
`;
