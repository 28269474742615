import { Tag } from '@dimatech/shared/lib/models';

export type SearchFilter = {
  searchTerm?: string;
  tagIds?: string[];
  customerIds?: string[];
  phases?: Phase[];
};

export type Project = {
  customerId: string;
  projectId: string;
  customerName: string;
  title: string;
  description?: string;
  benefitsAndEffects?: string;
  budget?: number;
  projectPhase?: Phase;
  nationalTags?: Tag[];
  isContacted?: boolean;
  isFollowed?: boolean;
  contactedDate?: string;
  followedDate?: string;
};

export type PublishedCustomer = {
  customerId: string;
  customerName: string;
};

// TODO: Move to some shared Pios package?
export enum Phase {
  NotSet = 'NotSet',
  // Draft = 'Draft',
  // Ready = 'Ready',
  ReadyForReview = 'ReadyForReview',
  NotStartedDecided = 'NotStartedDecided',
  Started = 'Started',
  Paused = 'Paused',
  Finished = 'Finished',
  FinishedEffectRealizationStarted = 'FinishedEffectRealizationStarted',
  FinishedEffectRealizationConcluded = 'FinishedEffectRealizationConcluded',
}
